// meed/src/util/admin.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import axios from 'axios';

let storageRef, app;

// Disable React DevTools in production
if (process.env.REACT_APP_ENV_TYPE === 'production') {
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => { } : null;
        }
    }
}
// Configuration for different environments
const firebaseConfig = {
    production: {
        apiKey: "AIzaSyAhE9bQpvSnYaTzp7graC2BrgtR9U4Kw9k",
        authDomain: "covenant-28c21.firebaseapp.com",
        databaseURL: "https://covenant-28c21.firebaseio.com",
        projectId: "covenant-28c21",
        storageBucket: "covenant-28c21.appspot.com",
        messagingSenderId: "1053987706594",
        appId: "1:1053987706594:web:85eb2ad3ff473d687b30bb",
        measurementId: "G-Z14W8XXQ95"
    },
    development: {
        apiKey: "AIzaSyAxxyWKeDwT5gO5Klx9C0S_esl-DzvzsHU",
        authDomain: "meed-dev.firebaseapp.com",
        databaseURL: "https://meed-dev.firebaseio.com",
        projectId: "meed-dev",
        storageBucket: "meed-dev.appspot.com",
        messagingSenderId: "563518078673",
        appId: "1:563518078673:web:349c19cba1b15ff2700ff0",
        measurementId: "G-2NF4YFLLC5"
    },
    local: {
        apiKey: "AIzaSyAxxyWKeDwT5gO5Klx9C0S_esl-DzvzsHU",
        authDomain: "meed-dev.firebaseapp.com",
        databaseURL: "https://meed-dev.firebaseio.com",
        projectId: "meed-dev",
        storageBucket: "meed-dev.appspot.com",
        messagingSenderId: "563518078673",
        appId: "1:563518078673:web:349c19cba1b15ff2700ff0",
        measurementId: "G-2NF4YFLLC5"
    },
    // Add other environments as needed
};


try {
    console.log("REACT_APP_ENV_TYPE: ", process.env.REACT_APP_ENV_TYPE)
    app = initializeApp(firebaseConfig[process.env.REACT_APP_ENV_TYPE] || firebaseConfig.local);
    storageRef = getStorage(app);
    getAnalytics(app);

    switch (process.env.REACT_APP_ENV_TYPE) {
        case 'production':
            axios.defaults.baseURL = 'https://meed.app/api/';
            break;
        case 'local':
            // axios.defaults.baseURL = 'http://localhost:5000/covenant-28c21/us-central1/api';
            axios.defaults.baseURL = "http://localhost:5000/meed-dev/us-east1/api"
            break;
        default:
            // axios.defaults.baseURL = 'https://us-central1-meed-dev.cloudfunctions.net/api';
            // https://us-east1-meed-dev.cloudfunctions.net/api
            axios.defaults.baseURL = 'https://meed-dev.web.app/api';
            break;
    }

    // Setting withCredentials to true for all Axios requests
    axios.defaults.withCredentials = true;
} catch (err) {
    console.log('error')
}

// Initialize Firebase Authentication and export it
const auth = getAuth(app);
export {
    storageRef,
    auth
}