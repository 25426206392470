// meed/src/pages/integrationsDiscord.js
import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
// MUI Stuff
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/layout/TabPanel';
// Redux stuff
// import Filter from 'bad-words';
// Redux stuff
import { connect } from 'react-redux';
import swords from '../images/swords.png';

import integrationsDiscordStyles from './styles/integrationsDiscordStyles';

// money only counts for ppl with accounts on both sides
// only email, no from field
class integrationsDiscord extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false,
      open1: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
      Twitch: window.Twitch,
      searchChall: true,
      socialName: '',
      payAmount: 0,
      open: false,
      body: '',
      endDate: 'xxlarge',
      completeByDate: 'xxlarge',
      influencer: '',
      timeout: null,
      loading: false,
      userHandleUrl: null,
      challengeCost: 0,
      mpChecked: true,
      tChecked: false,
      disabled: false,
      goal: 'No',
      tabValue: 0
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      open1: true
    });
  }
  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  };
  scrollToTopOfList = () => {
    const headerElement = document.getElementById('topOfList'); // Replace 'yourHeaderId' with the actual ID of your header
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.once) {
      if (this.props.home) {

      }
    }
  }
  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.team}>
        <Grid container className={classes.reelWrap}>
          {/* <div className={classes.bgThumb}></div> */}
          <Grid className={`${classes.gridSpacingNone} ${classes.topJoint} ${classes.mainGrid}`} xs={12}>
            <div className={classes.cta}>
              <div className={classes.ctaText}>
                <h1>Available Commands</h1>
              </div>
              <div className={classes.ctaSection}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={this.scrollToTopOfList}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="0 0 34.875 34.875">
                    <path id="Icon_awesome-chevron-circle-down" data-name="Icon awesome-chevron-circle-down" d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.434,17.434,0,0,1,35.438,18ZM19.2,26.009l9.527-9.527a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L18,20.046,10.856,12.9a1.681,1.681,0,0,0-2.384,0l-1.2,1.2a1.681,1.681,0,0,0,0,2.384L16.8,26.009A1.689,1.689,0,0,0,19.2,26.009Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                  </svg>

                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.moreWrap}>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.needHelpDescFormat}`} item sm={6} xs={12}>
            <div className={` ${classes.pTextSize} `}>
              <span className={classes.pTextSizeBold}> Welcome to the MeedBot Command Center for Discord. </span>
              Get ready to challenge and be challenged! To engage in Meed's challenges, link your Discord account and dive into the fray. Explore the commands below to unleash the full potential of Meed on your server!

            </div>
          </Grid>
          <Grid className={`${classes.gridSpacingNone} ${classes.needHelpIntegFormat}`} item sm={6} xs={12}>
            <Grid container className={classes.integrationSection}>
              <Grid className={classes.integrationSectionText1} item md={6} xs={12}> Add MeedBot to Discord </Grid>
              <Grid className={classes.integrationSectionText2} item md={6} xs={12}><Button
                variant="contained"
                color="primary"
                className={classes.startIntegrationButton}
                href="https://discord.com/oauth2/authorize?client_id=1039218567297646654&scope=identify%20bot%20applications.commands&permissions=2146958591"
                target="_blank"
                rel="noopener noreferrer"
              >
                Start Integration
              </Button>
              </Grid>

              {/*<img className={classes.needHelpImg} src={thumb}></img>*/}
            </Grid>
          </Grid>
        </Grid>
        <Fragment>
          <div id="topOfList" className={`${classes.commandContainerFormat1}`}>
            <AppBar className={`${classes.tabHeader} ${classes.commandListFormat}`} position="static" color="default">
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleChangeTab}
                color="#ECECEC"
                indicatorColor="#ECECEC"
                textColor="#FFF"
                fullWidth="true"
                variant="scrollable"
                scrollButtons="auto"
                //backgroundColor="#ECECEC"
                className={classes.tabHeader}
                aria-label="scrollable auto tabs example"
              >
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 0 ? classes.commandActiveButton : ''} `} label="All" {...this.a11yProps(0)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 1 ? classes.commandActiveButton : ''} `} label="Challenge" {...this.a11yProps(1)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 2 ? classes.commandActiveButton : ''} `} label="Help" {...this.a11yProps(2)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 3 ? classes.commandActiveButton : ''} `} label="Proof" {...this.a11yProps(3)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 4 ? classes.commandActiveButton : ''} `} label="User" {...this.a11yProps(4)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 5 ? classes.commandActiveButton : ''} `} label="AI Challenge" {...this.a11yProps(5)} />
                {/*<Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 6 ? classes.commandActiveButton : ''} `} label="Random" {...this.a11yProps(6)} />*/}

              </Tabs>
            </AppBar>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={0}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-challenge
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Challenge a user directly with /meed-challenge, followed by their username and the dare. Example: “/meed-challenge @GWashington Cross the Delaware in under 60 seconds.” Set a voting type to determine how the community's votes are counted, with voting open for 24 hours after the challenge ends.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-challenge [@user] [challenge description]
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      This command helpfully returns a brief description of Meed, core ideas, and links to this page. Simply type /meed to use it.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-proof
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Submit proof for a challenge with /meed-proof. Attach your evidence to the command with the unique Challenge ID. For instance, to upload a video of @GWashington’s Delaware crossing, enter “/meed-proof XYZ123” and attach the file. Find the Challenge ID on the Discord Challenge Card or on Meed by visiting the challenge link on the user's profile.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-proof [challengeID]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-user
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Verify a user’s presence on Meed and get a direct link to their profile with /meed-user. For example, “/meed-user @GWashington” will confirm if the father of America has enlisted on Meed and, if so, provide a quick route to his profile.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-user [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-gpt
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Enlist the help of an AI adjutant to craft a challenge with `/meed-gpt`. Input a seed for inspiration or leave it to fate. For instance, “/meed-gpt @KingGeorge Stamp out the rebellion” might have the AI command King George III to devise a strategy against @GWashington. Let the AI muse and deliver a challenge fit for a king or a general.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-gpt [@user] [text]
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/*
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-user-challenges
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      This command returns a list of the targeted user’s top Open and Active Challenges. Typing “/meed-user-challenges @KG3” would return KG3’s top challenges.                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-user-challenges [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
    */}
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={1}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-challenge
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Challenge a user directly with /meed-challenge, followed by their username and the dare. Example: “/meed-challenge @GWashington Cross the Delaware in under 60 seconds.” Set a voting type to determine how the community's votes are counted, with voting open for 24 hours after the challenge ends.

                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-challenge [@user] [challenge description]
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={2}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      This command helpfully returns a brief description of Meed, core ideas, and links to this page. Simply type /meed to use it.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={3}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-proof
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Submit proof for a challenge with /meed-proof. Attach your evidence to the command with the unique Challenge ID. For instance, to upload a video of @GWashington’s Delaware crossing, enter “/meed-proof XYZ123” and attach the file. Find the Challenge ID on the Discord Challenge Card or on Meed by visiting the challenge link on the user's profile.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-proof [challengeID]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={4}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-user
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Verify a user’s presence on Meed and get a direct link to their profile with /meed-user. For example, “/meed-user @GWashington” will confirm if the father of America has enlisted on Meed and, if so, provide a quick route to his profile.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-user [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={5}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-gpt
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Enlist the help of an AI adjutant to craft a challenge with `/meed-gpt`. Input a seed for inspiration or leave it to fate. For instance, “/meed-gpt @KingGeorge Stamp out the rebellion” might have the AI command King George III to devise a strategy against @GWashington. Let the AI muse and deliver a challenge fit for a king or a general.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-gpt [@user] [text]
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            {/*
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={5}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      /meed-user-challenges
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      This command returns a list of the targeted user’s top Open and Active Challenges. Typing “/meed-user-challenges @KG3” would return KG3’s top challenges.                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-user-challenges [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
  */}
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={6}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <p className={`${classes.thick}`}>
                      /meed-random
                    </p>
                    <p className={`${classes.commandDescription}`}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Quisque consequat imperdiet quam in tristique. Curabitur
                      eget tellus viverra lacus eleifend egestas vitae a ligula.
                      Curabitur aliquet eget risus nec porta.
                    </p>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      /meed-random text here

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </Fragment>
      </div>
    );
  }
}

integrationsDiscord.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(integrationsDiscordStyles)(integrationsDiscord));