// meed/src/pages/about.js
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
// MUI Stuff
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAnalytics, logEvent } from "firebase/analytics";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
// Redux stuff
import { postChallenge } from "../redux/actions/dataActions";
import { toast } from "react-toastify";
// import Filter from 'bad-words';
// Redux stuff
import { connect } from "react-redux";
import SearchUsers from "../components/layout/SearchUser";
import { loginUser } from "../redux/actions/userActions";
import { authenticateViaTwitter } from "../redux/actions/userActions";
import team1 from "../images/team1.jpg";
import team2 from "../images/team2.png";
import team3 from "../images/team3.jpg";
import team4 from "../images/team4.png";
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import personIcon from "../images/no-img.png";
import aboutStyles from "./styles/aboutStyles";
import { getRandomChallenge } from "../util/challengeGenerator";
const analytics = getAnalytics();

// money only counts for ppl with accounts on both sides
// only email, no from field
class about extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      submitted: false,
      open1: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
      Twitch: window.Twitch,
      searchChall: true,
      socialName: "",
      payAmount: 0,
      open: false,
      open2: false,
      body: "",
      endDate: "xxlarge",
      completeByDate: "xxlarge",
      influencer: "",
      timeout: null,
      loading: false,
      userHandleUrl: null,
      challengeCost: 0,
      mpChecked: true,
      tChecked: false,
      disabled: false,
      goal: "No",
      votingType: "default",
      randomChallenge: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  clearInfluencer = () => {
    this.setState({ influencer: "" });
  };
  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      open1: true,
    });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleCloseModal1 = () => {
    this.setState({ open1: false, errors: {} });
    this.clearInfluencer();
  };
  handleCloseModal2 = () => {
    this.setState({ open2: false, errors: {} });
    this.clearInfluencer();
  };
  signupNav = () => {
    // this.props.clearErrors();
    this.props.history.push("/signup");
  };
  handleChange = (event) => {
    if (event.target.name === "payAmount" && event.target.value < 0) {
      event.target.value = 0;
    }

    this.setState({ [event.target.name]: event.target.value });
  };
  cleanInput = () => {
    this.setState({
      title: "",
      body: "",
      payAmount: 0,
    });
  };
  selectUser = async (username) => {
    this.setState({
      influencer: username,
    });
  };
  handleSubmit = async (event) => {
    // Prevent the default form submission behavior
    event.preventDefault();

    // Destructure needed props and state
    const { user, postChallenge } = this.props;
    const { title, email, ...restState } = this.state;

    // If the title is missing, display an error and exit the function
    if (!title) {
      this.displayError(
        "Challenge not submitted, please enter a title.",
        "title",
      );
      return;
    }

    // Determine the email to be used, priority: user credentials email, state email
    const userEmail = user?.credentials?.email || email;

    // If the user doesn't exist or the user doesn't have a handle and no userEmail is determined
    if ((!user || (user && !user.handle)) && !userEmail) {
      this.displayError(
        "Challenge not submitted, please enter your email.",
        "email",
      );
      return;
    }

    // Disable the submit button to prevent multiple submissions
    this.setState({ disabled: true });

    try {
      // Attempt to post the challenge using provided details
      const res = await postChallenge({
        anyoneCanAccept: true,
        challengeName: title,
        fromEmail: userEmail || "",
        userHandle: user.credentials.handle ? user.credentials.handle : null,
        userId: user.credentials.userId ? user.credentials.userId : null,
        ...restState,
      });
      // Check if the response has a successful challenge data
      const chall = res?.challengeData;
      if (chall?.challengeName) {
        // If successful, display a success toast and update state
        toast.success("SUCCESS: Challenge submitted!");
        this.setState({ homeChallReturned: chall, open1: false, open2: true });
      } else if (res?.error) {
        // If there's an error in the response, display it
        this.displayError("Challenge not submitted!!!", res?.error);
      }
    } catch (err) {
      // If the post request fails, display a generic error
      this.displayError("Challenge not submitted!");
    }

    // After a delay, re-enable the submit button
    this.setState({ influencer: "" });
    setTimeout(() => this.setState({ disabled: false }), 5000);

    // Log the submission event for analytics
    logEvent(analytics, "home_post_challenge", {
      currentUserHandle: user?.authenticated ? user.credentials.handle : "anon",
    });
  };

  // Helper function to display errors
  displayError = (message, fieldError) => {
    // Display the error message as a toast
    toast.error(message);
    const errorObj = {};
    // If a specific field caused the error, add it to the errors state
    if (fieldError) {
      errorObj[fieldError] = `Please enter ${fieldError}`;
    }
    // Set errors in state and disable the form/button
    this.setState({
      disabled1: true,
      errors: { ...this.state.errors, ...errorObj },
    });
    // After a delay, re-enable the form/button
    setTimeout(() => this.setState({ disabled1: false }), 5000);
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      randomChallenge: getRandomChallenge(), // Set the random challenge when the component mounts
    });
    if (!this.state.once) {
      if (this.props.home) {
      }
    }
  }
  render() {
    const { errors } = this.state;
    const {
      classes,
      user: {
        credentials: { handle },
      },
    } = this.props;

    return (
      <div className={classes.about}>
        <Grid container className={classes.reelWrap}>
          {/* <div className={classes.bgThumb}></div> */}
          <Grid
            item
            className={`${classes.gridSpacingNone} ${classes.topJoint} ${classes.mainGrid}`}
            xs={12}
          >
            <div className={classes.cta}>
              <div className={classes.ctaText}>
                <h1>Challenge accepted</h1>
              </div>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={this.handleClickOpen}
                >
                  Make a challenge
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.moreWrap}>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid}`}
            item
            sm={12}
            md={12}
          >
            <div className={classes.ctaText}>
              <h1>meed (noun)</h1>
              <br></br>
              <p>
                {`
                        Meed is the handshake of the digital world.
                        We offer a secure place for influencers and fans to send and receive challenges,
                         while making sure everyone gets their due share.
                      `}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.exampleWrap}>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.mascotGrid}`}
            item
            sm={6}
            xs={12}
          >
            <div className={classes.ctaText}>
              <h1>
                <span className={classes.pink}>Gaming</span> YouTube Twitter
                Instagram Live Streams Friends Family Sports Motivation Exercise
                Crafts Cooking Musicians Concerts Artists and MORE!
              </h1>
            </div>
          </Grid>
        </Grid>
        <Grid container className={`${classes.moreWrap} ${classes.whiteBg}`}>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid}`}
            item
            sm={12}
            md={12}
          >
            <div
              className={`${classes.ctaText} ${classes.pink} ${classes.mid}`}
            >
              <p>
                {`
                        Challenge someone
                        or get ready to be Challenged!
                      `}
              </p>
              <br></br>
              <br></br>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  component={Link}
                  to={"/signup"}
                >
                  Join Today
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={`${classes.moreWrap}`}>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`}
            item
            sm={4}
            xs={12}
          >
            <div className={`${classes.img1} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <img src={icon1} alt="Crossed Swords Icon"></img>
                <p>
                  {`
                        Once a Challenge is complete, the money is yours.
                      `}
                </p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <h2>Fund Projects</h2>
              <p>
                {`
                      Support your favorite creators reliably!
                      Money is only passed on to the creator when the Challenge is successfully completed!
                    `}
              </p>
            </div>
          </Grid>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`}
            item
            sm={4}
            xs={12}
          >
            <div className={`${classes.img2} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <img src={icon2}></img>
                <p>
                  {`
                        Upvote and Boost Challenges to show creators your love.
                      `}
                </p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <h2>Create Fun Content</h2>
              <p>
                {`
                      On Meed you can create Challenges to motivate yourself, dare your friends (or strangers!?),
                      and engage with your favorite creators.
                    `}
              </p>
            </div>
          </Grid>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.cardCta}`}
            item
            sm={4}
            xs={12}
          >
            <div className={`${classes.img3} ${classes.mid}`}>
              <div className={`${classes.ctaTextCard} ${classes.mid}`}>
                <img src={icon3}></img>
                <p>
                  {`
                        Challenge someone
                        or get ready to be Challenged!
                      `}
                </p>
              </div>
            </div>
            <div className={`${classes.ctaTextInfo} ${classes.smoll}`}>
              <h2>Find Ideas</h2>
              <p>
                {`
                      We make it easy for creators to connect with their followers! 
                      Viewers upvote or pay for the content they want to see!
                    `}
              </p>
            </div>
          </Grid>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid}`}
            item
            sm={12}
            md={12}
          >
            <div
              className={`${classes.ctaText} ${classes.pink} ${classes.mid}`}
            >
              <p>
                {`
                      Discover the Endless Possibilities with Our Platform Integrations!
                    `}
              </p>
              <br></br>
              <br></br>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  component={Link}
                  to={"/integrations"}
                >
                  Explore Integrations
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.footer}>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid}`}
            item
            sm={4}
            xs={12}
          >
            <div
              className={`${classes.ctaText} ${classes.pink} ${classes.mid}`}
            >
              <p>
                {`
                      Meet the Meed Team.
                      We made it happen.
                      You can thank us later.
                    `}
              </p>
              <br></br>
              <br></br>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  component={Link}
                  to={"/team"}
                >
                  About the Team
                </Button>
              </div>
            </div>
          </Grid>
          <Grid
            className={`${classes.gridSpacingNone} ${classes.mainGrid}`}
            item
            sm={8}
            xs={12}
          >
            <div className={`${classes.ctaImgs} ${classes.mid}`}>
              <a
                href="https://www.linkedin.com/in/michael-campbell-73159b5a/"
                target="_blank"
                rel="noreferrer"
              >
                <img className={classes.team2} src={team2}></img>
              </a>
              <a
                href="https://www.linkedin.com/in/armante-washington-30b13749/"
                target="_blank"
                rel="noreferrer"
              >
                <img className={classes.team1} src={team1}></img>
              </a>
              <a
                href="https://www.linkedin.com/in/joshua-singh-0341725a/"
                target="_blank"
                rel="noreferrer"
              >
                <img className={classes.team3} src={team3}></img>
              </a>
              <a
                href="https://www.linkedin.com/in/ella-pravetz-4511122a/"
                target="_blank"
                rel="noreferrer"
              >
                <img className={classes.team4} src={team4}></img>
              </a>
            </div>
          </Grid>
        </Grid>
        {/** **************************** Challenge Modal Begins ******************************/}
        <Dialog
          PaperProps={{ classes: { root: classes.dialogPaper } }}
          open={this.state.open1}
          onClose={this.handleCloseModal1}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialog}
        >
          <div className={classes.closeButton} onClick={this.handleCloseModal1}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.434"
              height="25.434"
              viewBox="0 0 25.434 25.434"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M32.934,10.062,30.372,7.5,20.217,17.655,10.062,7.5,7.5,10.062,17.655,20.217,7.5,30.372l2.562,2.562L20.217,22.778,30.372,32.934l2.562-2.562L22.778,20.217Z"
                transform="translate(-7.5 -7.5)"
                fill="#959595"
              />
            </svg>
          </div>
          <div className={classes.modalWrap}>
            <div className={classes.searchBar}>
              <SearchUsers selectUser={this.selectUser} home="true" />
            </div>
            <div className={classes.challengeArea}>
              <Grid container>
                <Grid
                  className={`${classes.gridSpacingNone} ${classes.mainGridModal}`}
                  item
                  sm={6}
                  xs={12}
                >
                  <Card className={classes.card}>
                    <CardContent className={classes.content}>
                      <form onSubmit={this.handleSubmit}>
                        {/* {this.state.open && <List className={classes.root, classes.twitterUsersList} chooseUser={this.chooseUser}>
                        {twitterProfiles}
                      </List>}               */}
                        <Typography
                          variant="body1"
                          className={classes.pageText}
                        >
                          Challenge
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true, maxLength: 40 }}
                          name="title"
                          variant="outlined"
                          type="text"
                          multiline
                          rows="4"
                          placeholder={this.state.randomChallenge}
                          inputProps={{
                            maxLength: 80,
                            disableUnderline: true,
                            className: classes.whiteTxt,
                          }}
                          helperText={errors.title}
                          error={errors.title ? true : false}
                          className={classes.textField}
                          onChange={this.handleChange}
                          fullWidth
                        />
                        {!handle ? (
                          <Typography
                            variant="body1"
                            className={classes.pageText}
                          >
                            Your Email
                          </Typography>
                        ) : (
                          <div></div>
                        )}
                        {!handle ? (
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                              maxLength: 1000,
                            }}
                            name="email"
                            variant="outlined"
                            type="email"
                            placeholder="youremail@provider.com"
                            inputProps={{
                              maxLength: 100,
                              disableUnderline: true,
                              className: classes.whiteTxt,
                            }}
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            className={classes.textField}
                            onChange={this.handleChange}
                            fullWidth
                          />
                        ) : (
                          <div></div>
                        )}
                        <Grid className={classes.wrapper} container spacing={0}>
                          <Grid
                            className={classes.gridSpacingNone}
                            item
                            sm={12}
                            xs={12}
                          >
                            <Tooltip
                              title="This sets how much time this user will have to complete your challenge"
                              placement="top"
                            >
                              <Typography
                                variant="body1"
                                className={classes.pageText}
                              >
                                Time to Complete
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid
                            className={`${classes.gridSpacingNone}`}
                            item
                            sm={12}
                            xs={12}
                          >
                            <Select
                              // TODO: classes.outlined was removed  from the enclosing grid, do we keep this here?
                              variant="outlined"
                              disableUnderline={true}
                              className={`${classes.endDate} ${classes.textField}`}
                              id="date"
                              name="completeByDate"
                              value={this.state.completeByDate}
                              onChange={this.handleChange}
                              fullWidth
                            >
                              <MenuItem value={"small"}>30 Minutes</MenuItem>
                              <MenuItem value={"medium"}>1 hour</MenuItem>
                              <MenuItem value={"large"}>6 hours</MenuItem>
                              <MenuItem value={"xlarge"}>12 hours</MenuItem>
                              <MenuItem value={"xxlarge"}>1 Day</MenuItem>
                              <MenuItem value={"xxxlarge"}>3 Days</MenuItem>
                              <MenuItem value={"xxxxlarge"}>6 Days</MenuItem>
                            </Select>
                          </Grid>
                          <Grid
                            className={classes.wrapper}
                            container
                            spacing={0}
                          >
                            <Grid
                              className={classes.gridSpacingNone}
                              item
                              sm={12}
                              xs={12}
                            >
                              <Tooltip
                                title="Choose how the outcome of the challenge is decided"
                                placement="top"
                              >
                                <Typography
                                  variant="body1"
                                  className={classes.pageText}
                                >
                                  Voting Type
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid
                              className={`${classes.gridSpacingNone}`}
                              item
                              sm={12}
                              xs={12}
                            >
                              <Select
                                // TODO: classes.outlined was removed  from the enclosing grid, do we keep this here?
                                variant="outlined"
                                disableUnderline={true}
                                className={`${classes.endDate} ${classes.textField}`}
                                id="votingType"
                                name="votingType"
                                value={this.state.votingType}
                                onChange={this.handleChange}
                                fullWidth
                              >
                                <MenuItem value="default">
                                  Default - Only the targeted user's vote
                                  counts.
                                </MenuItem>
                                <MenuItem value="all">
                                  All - All votes count towards the outcome.
                                </MenuItem>
                                <MenuItem value="both">
                                  Both - Only the creator and target's votes
                                  count towards the outcome
                                </MenuItem>
                                <MenuItem value="boost">
                                  Boost - Only users who boosted the challenge
                                  have their votes count.
                                </MenuItem>
                                <MenuItem value="creator">
                                  Creator - Only the creator's vote counts.
                                </MenuItem>
                                {/* <MenuItem value="custom">Custom - Only users selected by the creator get an official vote</MenuItem> */}
                              </Select>
                            </Grid>
                          </Grid>
                          {handle ? (
                            <Grid
                              className={classes.gridSpacingNone}
                              item
                              sm={12}
                              xs={12}
                            >
                              <Tooltip
                                title={
                                  this.state.goal === "No"
                                    ? `Send your money to this user! This sets how much money you will give this user when the challenge is completed.
      If the challenge expires or is failed, your money will be added back to your account.`
                                    : "Create a goal for others to see! Let everyone know how much it takes to do this challenge."
                                }
                                placement="top"
                              >
                                <Typography
                                  variant="body1"
                                  className={classes.pageText}
                                >
                                  {this.state.goal === "No"
                                    ? "Add Reward (Optional)"
                                    : "Goal Amount"}
                                </Typography>
                              </Tooltip>
                            </Grid>
                          ) : (
                            <div></div>
                          )}
                          {handle ? (
                            <Grid
                              className={classes.gridSpacingNone}
                              item
                              sm={12}
                              xs={12}
                            >
                              <TextField
                                variant="outlined"
                                disableUnderline={true}
                                className={`${classes.endDate} ${classes.textField}`}
                                placeholder="$10"
                                name="payAmount"
                                onChange={this.handleChange}
                                error={errors.body ? true : false}
                                type="number"
                                fullWidth
                                inputProps={{
                                  className: classes.whiteTxt,
                                }}
                              />
                            </Grid>
                          ) : (
                            <div></div>
                          )}
                        </Grid>

                        {!this.state.influencer ? (
                          <Button
                            variant="contained"
                            className={classes.submitButtonChall}
                            disabled={
                              !this.state.title ||
                              (!handle && !this.state.email)
                            }
                          >
                            Create and Send
                            <svg
                              className={classes.planeIcon}
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.998"
                              height="18.004"
                              viewBox="0 0 17.998 18.004"
                            >
                              <path
                                id="Icon_awesome-paper-plane"
                                data-name="Icon awesome-paper-plane"
                                d="M16.735.111.44,9.512a.844.844,0,0,0,.077,1.519L4.254,12.6l10.1-8.9a.211.211,0,0,1,.3.292L6.188,14.308v2.83a.844.844,0,0,0,1.494.555l2.232-2.718,4.38,1.835a.846.846,0,0,0,1.16-.64L17.986.983A.844.844,0,0,0,16.735.111Z"
                                transform="translate(-0.001 0.002)"
                                fill="#fff"
                              />
                            </svg>
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            className={classes.submitButtonChall}
                            disabled={
                              this.state.disabled ||
                              this.state.disabled1 ||
                              this.state.disabled2 ||
                              !this.state.title
                            }
                          >
                            {handle &&
                            this.props.userHandle &&
                            this.props.userHandle === handle
                              ? this.state.goal === "Yes"
                                ? `Submit, ${this.state.payAmount && parseInt(this.state.payAmount) !== 0 ? "$" + this.state.payAmount : "$0"} goal`
                                : `Submit ${this.state.payAmount && parseInt(this.state.payAmount) !== 0 ? "$" + this.state.payAmount : ""}`
                              : `Submit for $${this.state.challengeCost + parseInt(this.state.payAmount ? this.state.payAmount : 0)}`}
                            <svg
                              className={classes.planeIcon}
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.998"
                              height="18.004"
                              viewBox="0 0 17.998 18.004"
                            >
                              <path
                                id="Icon_awesome-paper-plane"
                                data-name="Icon awesome-paper-plane"
                                d="M16.735.111.44,9.512a.844.844,0,0,0,.077,1.519L4.254,12.6l10.1-8.9a.211.211,0,0,1,.3.292L6.188,14.308v2.83a.844.844,0,0,0,1.494.555l2.232-2.718,4.38,1.835a.846.846,0,0,0,1.16-.64L17.986.983A.844.844,0,0,0,16.735.111Z"
                                transform="translate(-0.001 0.002)"
                                fill="#fff"
                              />
                            </svg>
                          </Button>
                        )}
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  className={`${classes.gridSpacingNone} ${classes.mainGridModal}`}
                  item
                  sm={6}
                  xs={12}
                >
                  <div>
                    <div className={classes.ideas}>
                      <svg
                        className={classes.dice}
                        xmlns="http://www.w3.org/2000/svg"
                        width="62.641"
                        height="50.113"
                        viewBox="0 0 62.641 50.113"
                      >
                        <path
                          id="Icon_awesome-dice"
                          data-name="Icon awesome-dice"
                          d="M57.943,18.792H46.321a7.935,7.935,0,0,1-1.664,8.742L31.32,40.871v4.543a4.7,4.7,0,0,0,4.7,4.7H57.943a4.7,4.7,0,0,0,4.7-4.7V23.49A4.7,4.7,0,0,0,57.943,18.792ZM46.981,36.8a2.349,2.349,0,1,1,2.349-2.349A2.35,2.35,0,0,1,46.981,36.8ZM42.442,18.528,25.321,1.406a4.8,4.8,0,0,0-6.792,0L1.406,18.528a4.8,4.8,0,0,0,0,6.792L18.528,42.442a4.8,4.8,0,0,0,6.792,0L42.442,25.321a4.8,4.8,0,0,0,0-6.793ZM9.4,24.273a2.349,2.349,0,1,1,2.349-2.349A2.35,2.35,0,0,1,9.4,24.273ZM21.924,36.8a2.349,2.349,0,1,1,2.349-2.349A2.35,2.35,0,0,1,21.924,36.8Zm0-12.528a2.349,2.349,0,1,1,2.349-2.349A2.35,2.35,0,0,1,21.924,24.273Zm0-12.528A2.349,2.349,0,1,1,24.273,9.4,2.35,2.35,0,0,1,21.924,11.745ZM34.453,24.273A2.349,2.349,0,1,1,36.8,21.924,2.35,2.35,0,0,1,34.453,24.273Z"
                          fill="#fff"
                        />
                      </svg>
                      <h1 className={classes.signUp} onClick={this.signupNav}>
                        Sign Up
                      </h1>
                      <p>Get challenges from others</p>
                    </div>
                    <div className={classes.shareideas}>
                      <p
                        onClick={() => {
                          window.navigator.share({
                            title: "Meed",
                            text: "Someone sent you a challenge!",
                            url: "https://meed.app",
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25.823"
                          height="22.953"
                          viewBox="0 0 25.823 22.953"
                        >
                          <path
                            id="Icon_awesome-share-square"
                            data-name="Icon awesome-share-square"
                            d="M25.486,7.955l-6.456,6.1a1.077,1.077,0,0,1-1.815-.782V10.043c-6.481.043-9.216,1.574-7.387,7.682a.718.718,0,0,1-1.121.777A8.63,8.63,0,0,1,5.38,12.075c0-6.453,5.272-7.733,11.835-7.77V1.076A1.077,1.077,0,0,1,19.03.294l6.456,6.1A1.076,1.076,0,0,1,25.486,7.955ZM17.215,17v3.088H2.869V5.738H5.152a.538.538,0,0,0,.388-.166A8.74,8.74,0,0,1,7.826,3.884a.538.538,0,0,0-.249-1.015H2.152A2.152,2.152,0,0,0,0,5.021V20.8a2.152,2.152,0,0,0,2.152,2.152h15.78A2.152,2.152,0,0,0,20.084,20.8V16.82a.538.538,0,0,0-.718-.507,3.22,3.22,0,0,1-1.533.151A.539.539,0,0,0,17.215,17Z"
                            fill="#7b5df9"
                          />
                        </svg>
                        Share Meed with your friends
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                className={`${classes.gridSpacingNone} ${classes.footerGridModal}`}
                item
                sm={6}
                xs={12}
              ></Grid>
            </div>
          </div>
        </Dialog>
        {/** **************************** Challenge Modal Ends ******************************/}
        {/** **************************** Challenge Created Modal Begins ******************************/}
        <Dialog
          PaperProps={{ classes: { root: classes.dialogPaper } }}
          open={this.state.open2}
          onClose={this.handleCloseModal2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialog}
        >
          <div className={classes.closeButton} onClick={this.handleCloseModal2}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.434"
              height="25.434"
              viewBox="0 0 25.434 25.434"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M32.934,10.062,30.372,7.5,20.217,17.655,10.062,7.5,7.5,10.062,17.655,20.217,7.5,30.372l2.562,2.562L20.217,22.778,30.372,32.934l2.562-2.562L22.778,20.217Z"
                transform="translate(-7.5 -7.5)"
                fill="#959595"
              />
            </svg>
          </div>
          <div className={classes.modalWrap}>
            <div className={classes.searchBar}>
              <h1>
                Challenge to {this.state.homeChallReturned?.influencerAtName}{" "}
                sent!
              </h1>
            </div>
            <div className={classes.challengeArea}>
              <Grid container>
                <Grid className={`${classes.gridSpacingNone}`} item xs={12}>
                  <Card className={classes.card2}>
                    <CardContent className={classes.content}>
                      {/* {this.state.open && <List className={classes.root, classes.twitterUsersList} chooseUser={this.chooseUser}>
                        {twitterProfiles}
                      </List>}               */}

                      <div className={classes.visitProfile}>
                        <img
                          src={
                            this.state.homeChallReturned?.userImage ||
                            personIcon
                          }
                          alt="User Profile"
                        ></img>
                      </div>
                      <Button
                        variant="contained"
                        className={classes.visitButtonChall}
                        component={Link}
                        to={`/users/${this.state.homeChallReturned?.influencerAtName}/challenge/${this.state.homeChallReturned?.challengeId}`}
                      >
                        Visit Challenged Profile
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  className={`${classes.gridSpacingNone} ${classes.mainGridModal}`}
                  item
                  sm={12}
                  xs={12}
                >
                  <div>
                    <div className={classes.challengeLink}>
                      <p>Share this Challenge with your friends!</p>

                      <p
                        className={classes.challengeLink2}
                        onClick={() => {
                          window.navigator.share({
                            title: "Meed",
                            text: "Check out the challenge I created on Meed!",
                            url: `https://meed.app/users/${this.state.homeChallReturned?.influencerAtName}/challenge/${this.state.homeChallReturned?.challengeId}`,
                          });
                        }}
                      >
                        {`https://meed.app/users/${this.state.homeChallReturned?.influencerAtName}/challenge/${this.state.homeChallReturned?.challengeId}`}
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                className={`${classes.gridSpacingNone} ${classes.footerGridModal}`}
                item
                sm={6}
                xs={12}
              ></Grid>
            </div>
          </div>
        </Dialog>
        {/** **************************** Challenge Created Modal Ends ******************************/}
      </div>
    );
  }
}

about.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  authenticateViaTwitter: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
  authenticateViaTwitter,
  postChallenge,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withStyles(aboutStyles)(about));
