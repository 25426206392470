// meed/src/redux/actions/userActions.js
import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  SET_AUTHENTICATED,
  LOADING_USER,
  LOADED_USER,
  MARK_NOTIFICATIONS_READ,
  REPORT_CHALLENGE,
  SET_CSRF_TOKEN,
  CLEAR_CSRF_TOKEN,
  SHOW_TOAST,
  CLEAR_TOAST_MESSAGE,
  STOP_LOADING_UI,
  ADD_TO_WAITLIST,
} from '../types';
import axios from 'axios';
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  sendEmailVerification
} from "firebase/auth";
import { toast } from 'react-toastify';
import { ERROR_MAPPING } from '../errorMapping';
import AuthService from '../../services/AuthService';
import handleError from '../../util/errorHandler';
axios.defaults.withCredentials = true;
const auth = getAuth();
var googleProvider = new GoogleAuthProvider();
var facebookProvider = new FacebookAuthProvider();
var twitterProvider = new TwitterAuthProvider();
var emailProvider = new EmailAuthProvider();


export const showToastMessage = message => ({
  type: SHOW_TOAST,
  payload: message
});

export const clearToastMessage = () => ({
  type: CLEAR_TOAST_MESSAGE
});


/**
 * Authenticates a user via Google and handles subsequent user data processing, including obtaining a CSRF token for added security.
 * This function is kept separate from the Facebook and Twitter versions to accommodate potential provider-specific changes and nuances.
 * 
 * @function
 * @async
 * @param {Object} history - The history object used for navigation.
 * @param {string|null} referralCode - The referral code associated with user registration, if available.
 * @returns {Promise<void>} - Resolves upon successful authentication, user data processing, and CSRF token retrieval.
 * 
 * @throws Will throw an error if there is an issue during the authentication process, during the communication with the server, or if CSRF token retrieval fails.
 *
 * @example
 * authenticateViaGoogle(history, 'XYZ123').then(() => {
 *   console.log('User authenticated via Google!');
 * });
 */
export const authenticateViaGoogle = (history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  try {
    const result = await signInWithPopup(auth, googleProvider);
    if (!result) {
      console.error('Failed to sign in with Google.');
      return;
    }

    // const credential = result.credential;
    const user = result.user;
    // Get the ID token after third-party authentication
    const idToken = await user.getIdToken(true);
    /**
     * A helper function to handle user data on the server. It facilitates the user login or creation process using third-party authentication data.
     * This function is internally called within the third-party authentication functions and is not exported for use elsewhere.
     *
     * @function
     * @async
     * @param {Object} data - An object containing the user data to be sent to the server. It is expected to have properties such as `uid`, `authProvider`, `handle`, etc., with `token` being added within the function.
     * 
     * @returns {Promise<void>} - A promise resolving with no value upon the successful handling of user data on the server, including CSRF token retrieval and setup.
     * 
     * @throws Will throw an error if there's an issue during the server communication, during the CSRF token retrieval, or if there's an issue sending the email verification.
     *
     * @example
     * // Note: This function is not exported and is only intended to be called internally within third-party authentication functions.
     * handleUserDataOnServer({
     *   uid: '12345',
     *   authProvider: 'google',
     *   handle: 'user12345',
     *   // ... other properties
     * }).then(() => {
     *   console.log('User data handled on server successfully.');
     * });
     */
    const handleUserDataOnServer = async (data) => {
      data.token = idToken;
      try {
        const response = await axios.post('/thirdParty/google', data, {
          withCredentials: true
        });

        if (!user.emailVerified) {
          try {
            await sendEmailVerification(user);
          } catch (error) {
            console.log('Error sending verification email: ', error);
          }
        }
/*
        if (response.data && (response.data.status === 'existing' || response.data.status === 'new')) {

          const csrfToken = response.data.csrfToken;
          if (csrfToken) {
            dispatch({ type: SET_CSRF_TOKEN, payload: csrfToken });
            axios.defaults.headers.common['x-csrf-token'] = csrfToken;
          }
        }
*/
        if (idToken) {
          dispatch(getUserData());
          dispatch({ type: SET_AUTHENTICATED });
          dispatch({ type: CLEAR_ERRORS });
          history.push('/home');
        }
        return response
      } catch (error) {
        console.log('Error during process on server: ', error);
        dispatch({ type: SET_ERRORS, payload: error });
      }
    };

    const userData = {
      uid: user.uid || '',
      authProvider: 'google',
      handle: 'user' + Math.floor(100000 + Math.random() * 900000),
      email: user.email || '',
      thirdPartySignup: true,
      userImage: user.photoURL || '',
      referralCode: referralCode || ''
    };

    const response = await handleUserDataOnServer(userData);
    return response;

  } catch (error) {
    console.error('Error during Google authentication: ', error);
    handleError(error, dispatch, 'authenticateViaGoogle');
  }
};

/**
 * Authenticates a user via Facebook and handles subsequent user data processing, including obtaining a CSRF token for added security.
 * This function is kept separate from the Google and Twitter versions to accommodate potential provider-specific changes and nuances.
 * 
 * @function
 * @async
 * @param {Object} history - The history object used for navigation.
 * @param {string|null} referralCode - The referral code associated with user registration, if available.
 * @returns {Promise<void>} - Resolves upon successful authentication, user data processing, and CSRF token retrieval.
 * 
 * @throws Will throw an error if there is an issue during the authentication process, during the communication with the server, or if CSRF token retrieval fails.
 *
 * @example
 * authenticateViaFacebook(history, 'XYZ123').then(() => {
 *   console.log('User authenticated via Facebook!');
 * });
 */
export const authenticateViaFacebook = (history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const result = await signInWithPopup(auth, facebookProvider);
    if (!result) {
      console.log('Failed to sign in with Facebook.');
      return;
    }

    // const credential = result.credential;
    const user = result.user;

    // Get the ID token after third-party authentication
    const idToken = await user.getIdToken(true);

    /**
     * A helper function to handle user data on the server. It facilitates the user login or creation process using third-party authentication data.
     * This function is internally called within the third-party authentication functions and is not exported for use elsewhere.
     *
     * @function
     * @async
     * @param {Object} data - An object containing the user data to be sent to the server. It is expected to have properties such as `uid`, `authProvider`, `handle`, etc., with `token` being added within the function.
     * 
     * @returns {Promise<void>} - A promise resolving with no value upon the successful handling of user data on the server, including CSRF token retrieval and setup.
     * 
     * @throws Will throw an error if there's an issue during the server communication, during the CSRF token retrieval, or if there's an issue sending the email verification.
     *
     * @example
     * // Note: This function is not exported and is only intended to be called internally within third-party authentication functions.
     * handleUserDataOnServer({
     *   uid: '12345',
     *   authProvider: 'facebook',
     *   handle: 'user12345',
     *   // ... other properties
     * }).then(() => {
     *   console.log('User data handled on server successfully.');
     * });
     */
    const handleUserDataOnServer = async (data) => {
      data.token = idToken;
      try {
        const response = await axios.post('/thirdParty/facebook', data, {
          withCredentials: true
        });

        if (!user.emailVerified) {
          try {
            await sendEmailVerification(user);
          } catch (error) {
            console.log('Error sending verification email: ', error);
          }
        }
/*
        if (response.data && (response.data.status === 'existing' || response.data.status === 'new')) {

          const csrfToken = response.data.csrfToken;
          if (csrfToken) {
            dispatch({ type: SET_CSRF_TOKEN, payload: csrfToken });
            axios.defaults.headers.common['x-csrf-token'] = csrfToken;
          }       
        }
*/
        if (idToken) {
          dispatch(getUserData());
          dispatch({ type: SET_AUTHENTICATED });
          dispatch({ type: CLEAR_ERRORS });
          history.push('/home');
        }
        return response;
      } catch (error) {
        console.log('Error during process on server: ', error);
        dispatch({ type: SET_ERRORS, payload: error });
      }
    };

    const userData = {
      uid: user.uid || '',
      authProvider: 'facebook',
      handle: 'user' + Math.floor(100000 + Math.random() * 900000),
      email: user.email || '',
      thirdPartySignup: true,
      userImage: user.photoURL || '',
      referralCode: referralCode || ''
    };

    const response = await handleUserDataOnServer(userData);
    return response;
  } catch (error) {
    console.error('Error during Facebook authentication: ', error);
    handleError(error, dispatch, 'authenticateViaFacebook');
  }
};

/**
 * Authenticates a user via Twitter and handles subsequent user data processing, including obtaining a CSRF token for added security.
 * This function is kept separate from the Google and Facebook versions to accommodate potential provider-specific changes and nuances.
 * 
 * @function
 * @async
 * @param {Object} history - The history object used for navigation.
 * @param {string|null} referralCode - The referral code associated with user registration, if available.
 * @returns {Promise<void>} - Resolves upon successful authentication, user data processing, and CSRF token retrieval.
 * 
 * @throws Will throw an error if there is an issue during the authentication process, during the communication with the server, or if CSRF token retrieval fails.
 *
 * @example
 * authenticateViaTwitter(history, 'XYZ123').then(() => {
 *   console.log('User authenticated via Twitter!');
 * });
 */
export const authenticateViaTwitter = (history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  try {
    const result = await signInWithPopup(auth, twitterProvider);
    if (!result) {
      console.log('Failed to sign in with Twitter.');
      return;
    }

    const credential = result.credential;
    const user = result.user;
    const idToken = await user.getIdToken(true);

    const twitterTokens = {
      accessToken: credential?.accessToken,
      secret: credential?.secret
    };

    /**
     * A helper function to handle user data on the server. It facilitates the user login or creation process using third-party authentication data.
     * This function is internally called within the third-party authentication functions and is not exported for use elsewhere.
     *
     * @function
     * @async
     * @param {Object} data - An object containing the user data to be sent to the server. It is expected to have properties such as `uid`, `authProvider`, `handle`, etc., with `token` being added within the function.
     * 
     * @returns {Promise<void>} - A promise resolving with no value upon the successful handling of user data on the server, including CSRF token retrieval and setup.
     * 
     * @throws Will throw an error if there's an issue during the server communication, during the CSRF token retrieval, or if there's an issue sending the email verification.
     *
     * @example
     * // Note: This function is not exported and is only intended to be called internally within third-party authentication functions.
     * handleUserDataOnServer({
     *   uid: '12345',
     *   authProvider: 'twitter',
     *   handle: 'user12345',
     *   // ... other properties
     * }).then(() => {
     *   console.log('User data handled on server successfully.');
     * });
     */
    const handleUserDataOnServer = async (data) => {
      data.token = idToken;
      try {
        const response = await axios.post('/thirdParty/twitter', data, {
          withCredentials: true
        });

        if (!user.emailVerified) {
          try {
            await sendEmailVerification(user);
          } catch (error) {
            console.log('Error sending verification email: ', error);
          }
        }
/*
        if (response.data && (response.data.status === 'existing' || response.data.status === 'new')) {

          const csrfToken = response.data.csrfToken;
          if (csrfToken) {
            dispatch({ type: SET_CSRF_TOKEN, payload: csrfToken });
            axios.defaults.headers.common['x-csrf-token'] = csrfToken;
          }
        }
*/
        if (idToken) {
          dispatch(getUserData());
          dispatch({ type: SET_AUTHENTICATED });
          dispatch({ type: CLEAR_ERRORS });
          history.push('/home');
        }
        return response;
      } catch (error) {
        console.log('Error during process on server: ', error);
        dispatch({ type: SET_ERRORS, payload: error });
      }
    };


    const userData = {
      uid: user.uid || '',
      authProvider: 'twitter',
      handle: 'user' + Math.floor(100000 + Math.random() * 900000),
      email: user.email || '',
      thirdPartySignup: true,
      userImage: user.photoURL || '',
      referralCode: referralCode || '',
      token: twitterTokens // TODO: Verify this information is being used correctly by Twitter in BE.
    };

    const response = await handleUserDataOnServer(userData);
    return response;

  } catch (error) {
    console.error('Error during Twitter authentication: ', error);
    handleError(error, dispatch, 'authenticateViaTwitter');
  }
};

/**
 * Signs up a user via Discord and handles subsequent user data processing.
 * 
 * @param {Object} history - The history object used for navigation.
 * @param {string|null} referralCode - The referral code associated with user registration, if available.
 * @returns {Promise<void>} - Resolves upon successful authentication and user data processing.
 */
export const signUpWithDiscord = (history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  const authConfig = {
    platform: 'discord',
    baseUri: 'https://discord.com/api/oauth2/authorize',
    clientId: process.env.DISCORD_CLIENT_ID,
    redirectUri: process.env.DISCORD_REDIRECT_URI,
    scope: 'identify email'
  };

  try {
    const tokenResponse = await AuthService.authenticate(authConfig, dispatch, (code) => getAuthToken('discord', code)(dispatch));

    if (tokenResponse && tokenResponse.success) {
      const { idToken, user } = tokenResponse.data;

      const userData = {
        uid: user.id,
        authProvider: 'discord',
        handle: 'user' + Math.floor(100000 + Math.random() * 900000),
        email: user.email,
        thirdPartySignup: true,
        userImage: user.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png` : '',
        referralCode: referralCode || ''
      };

      const response = await handleUserDataOnServer(userData, idToken, dispatch, history);
      return response;
    }
  } catch (error) {
    console.error('Error during Discord sign-up: ', error);
    handleError(error, dispatch, 'signUpWithDiscord');
  }
};

/**
 * Handles user data processing on the server after authentication with Discord.
 * This function sends the user data and ID token to the server for user creation or linking.
 * If successful, it updates the Redux store and redirects the user to the home page.
 *
 * @async
 * @function handleUserDataOnServer
 * @param {Object} data - The user data to be sent to the server.
 * @param {string} idToken - The ID token obtained from Discord authentication.
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Object} history - The history object used for navigation.
 * @returns {Promise<Object>} - A promise that resolves with the server response.
 * @throws Will log an error and dispatch an error action if the request fails.
 */
const handleUserDataOnServer = async (data, idToken, dispatch, history) => {
  data.token = idToken;
  try {
    // 
    const response = await axios.post('/thirdParty/discord', data, {
      withCredentials: true
    });

    if (response.data && response.data.status) {
      dispatch(getUserData());
      dispatch({ type: SET_AUTHENTICATED });
      dispatch({ type: CLEAR_ERRORS });
      history.push('/home');
    }

    return response;
  } catch (error) {
    console.log('Error during process on server: ', error);
    dispatch({ type: SET_ERRORS, payload: error });
  }
};



/**
 * Links a user's email with their current authentication method.
 * @param {Object} userData - The user data.
 * @param {string} userData.email - The email of the user.
 * @param {string} userData.password - The password of the user.
 * @param {Object} history - The browser history object.
 * @param {Function} dispatch - Redux dispatch method.
 * @returns {Promise<Object>} A promise that resolves with the response data or error.
 */
export const linkEmail = (userData, history) => async (dispatch, getState) => {
  // Dispatch the loading UI action
  dispatch({ type: LOADING_UI });

  // Generate the email and password credential for linking
  const credential = emailProvider.credential(userData.email, userData.password);

  try {
    // Accessing the Redux store to get the firebaseUser
    const firebaseUser = getState().user.firebaseUser;

    if (!firebaseUser) {
      throw new Error("No authenticated user found.");
    }

    // Try to link the credential with the current Firebase user
    const usercred = await linkWithCredential(firebaseUser, credential);

    // Try to post the user's email to the backend
    const res = await axios.post('/user', { email: userData.email });

    // Check the response and dispatch further actions if necessary
    if (res && res.data) {
      dispatch(getUserData());
      return res.data;
    } else if (res) {
      return res;
    }

  } catch (error) {
    console.error("Error during account linking or data posting:", error);
    handleError(error, dispatch, 'linkEmail');
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Creates an action to set the CSRF token in the Redux store.
 * 
 * @function setCsrfToken
 * @param {string} token - The CSRF token obtained from the server.
 * @returns {Object} The action object with the type `SET_CSRF_TOKEN` and the provided token as its payload.
 */
export const setCsrfToken = (token) => ({
  type: SET_CSRF_TOKEN,
  payload: token
});

/**
 * Creates an action to clear the CSRF token from the Redux store.
 * 
 * @function clearCsrfToken
 * @returns {Object} The action object with the type `CLEAR_CSRF_TOKEN`.
 */
export const clearCsrfToken = () => ({
  type: CLEAR_CSRF_TOKEN
});

/**
 * @function loginUser
 * @description Logs the user in using Firebase's client-side authentication. On successful authentication, sends the user's ID token to the backend to set a session cookie and then dispatches actions to retrieve user data, set CSRF cookie, and clear errors.
 * 
 * @param {Object} userData - The user data for logging in.
 * @param {string} userData.email - The email address of the user.
 * @param {string} userData.password - The user's password.
 * @param {Object} history - The browser's history object, which can be used for navigation if necessary.
 * @param {Function} dispatch - The Redux dispatch method.
 * 
 * @returns {Promise<boolean|Object>} Returns a promise that resolves with:
 * - `true` if login was successful.
 * - `false` if there was a failure in server-side session creation.
 * - An `error` object if an exception occurred during the process.
 * 
 * @throws Will throw an error if the login process encounters an issue.
 * 
 * @example 
 * const result = await loginUser({ email: "user@example.com", password: "securepass" }, history);
 * if(result === true) {
 *   // Login successful, navigate or trigger other events
 * } else if(result === false) {
 *   // Server-side session creation failed
 * } else {
 *   // Some other error occurred during the process
 * }
 */
export const loginUser = (userData, history) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    // Authenticate with Firebase on the client-side.
    const userCredential = await signInWithEmailAndPassword(auth, userData.email, userData.password);
    const token = await userCredential.user.getIdToken();

    // Send the token to the backend.
    const res = await axios.post('/login', { token: token });

    if (res && res.data && res.data.success) {
/*
      if (res.data.csrfToken) {
        dispatch({ type: SET_CSRF_TOKEN, payload: res.data.csrfToken });
        axios.defaults.headers.common['x-csrf-token'] = res.data.csrfToken;
      }
*/
      dispatch(getUserData());
      dispatch({ type: SET_AUTHENTICATED });
      dispatch({ type: CLEAR_ERRORS });

      return true;  // Indicates a successful login attempt
    } else {
      return false;  // Indicates a failed login attempt
    }
  } catch (error) {
    console.error("Error during login:", error);
    handleError(error, dispatch, 'loginUser');
    return false;
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};


/**
 * Fetches an anonymous token and sets it as an authorization header.
 *
 * @async
 * @function
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {void}
 * @throws {Error} When unable to fetch the anonymous token.
 */
/*
export const getAnonymousToken = () => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const response = await axios.get('/getAnonymousToken');

    if (response && response.data) {
      const token = response.data.user?.stsTokenManager?.accessToken;

      if (token) {
        setAuthorizationHeader(token);
      }
      dispatch({ type: CLEAR_ERRORS });
    }

  } catch (err) {
    console.error("Error fetching anonymous token:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      }
    } else {
      dispatch({
        type: SET_ERRORS,
        payload: err
      });
    }

    throw err;
  }
}
*/
// export const authenticateViaTwitter = (userToken, history) => (dispatch) => {
//   dispatch({ type: LOADING_UI });
//   setAuthorizationHeader(userToken);
//   dispatch(getUserData());
//   history.push('/home');
// };

/**
 * Signs up a new user, and handles subsequent operations like dispatching the new user data to the Redux store,
 * sending an email verification (if required), and navigating to the appropriate page post-signup.
 *
 * @function
 * @async
 *
 * @param {Object} newUserData - User data required for the signup process.
 * @param {string} newUserData.email - The user's email address.
 * @param {string} newUserData.password - The user's chosen password.
 * @param {Object} history - Browser history object to control navigation post-signup.
 * @param {string} [referralCode] - Optional referral code provided by the user.
 *
 * @returns {Promise<void>} A promise that resolves when the signup process and all subsequent operations are complete.
 * 
 * @throws Will throw an error if any error occurs during the signup process, which will be caught and handled by dispatching to the Redux store.
 * 
 * @example
 * signupUser({
 *   email: 'john.doe@example.com',
 *   password: 'securepassword123'
 * }, history, 'XYZ123')(dispatch);
 */
export const signupUser = (newUserData, history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  if (referralCode) {
    newUserData.referralCode = referralCode;
  }

  try {
    const signupResponse = await axios.post('/signup', newUserData);
    if (signupResponse && signupResponse.data && signupResponse.data.created) {
      // Sign in the user client-side
      const userCredential = await signInWithEmailAndPassword(auth, newUserData.email, newUserData.password);
      const idToken = await userCredential.user.getIdToken();

      // Call backend endpoint to set session cookie and CSRF token using the ID token
      const sessionResponse = await axios.post('/sessionLogin', { idToken });

      // Check for CSRF token in the response and set it in axios defaults
/*
      if (sessionResponse.data.csrfToken) {
        dispatch({ type: SET_CSRF_TOKEN, payload: sessionResponse.data.csrfToken });
        axios.defaults.headers.common['x-csrf-token'] = sessionResponse.data.csrfToken;
      }
*/
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: SET_AUTHENTICATED });
      dispatch(getUserData());

      // Redirect user after successful signup and session setup
      history.push(`/users/${signupResponse.data.handle || 'home'}`);

      toast.success("Sign Up complete, redirecting...");
    } else {
      toast.error("Sign Up Failed");
      dispatch({
        type: SET_ERRORS,
        payload: signupResponse.data
      });
    }
  } catch (error) {
    console.error("Error during signup:", error);
    handleError(error, dispatch, 'signupUser');
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};

/**
 * Logs the user out and redirects them to the login page.
 *
 * @function
 * @param {Object} [history] - The history object from React Router for navigation.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {void}
 */
export const logoutUser = (history, isForced = false) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    console.log({ history, isForced });
    delete axios.defaults.headers.common['Authorization'];

    // Make an API call to the server-side logout endpoint
    await axios.post('/logout');

    // Handle local app logout operations after the server has cleared the session cookie and token
    dispatch({
      type: SET_USER,
      payload: {
        authenticated: false,
        loading: false,
        likes: [],
        notifications: [],
        credentials: {
          handle: '',
          createdAt: '',
          imageUrl: '',
          bio: '',
          website: '',
          location: '',
          meedPointsBalance: '',
          tokens: '',
          twitch: ''
        }
      }
    });

    // Firebase sign out
    await signOut(auth);

  } catch (error) {
    console.error("Error during logout:", error);
    handleError(error, dispatch, 'logoutUser');
  } finally {
    dispatch({ type: SET_UNAUTHENTICATED });
    dispatch({ type: STOP_LOADING_UI });

    /*
    if (isForced && history) {
      history.push('/login');
    }
    */
  }
};

/**
 * Fetches the user data.
 * 
 * This function attempts to fetch user-specific data when a user is logged in.
 * 
 * @returns {Function} A dispatch function
 */
export const getUserData = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const res = await axios.get('/user', { withCredentials: true });
    if (res && res.data) {
      dispatch({
        type: SET_USER,
        payload: res.data
      });
    }
  } catch (error) {
    console.error('Error occurred while fetching user data:', error);
    handleError(error, dispatch, 'getUserData');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Asynchronously fetches refreshed user data from the server and dispatches actions based on the fetched data.
 * 
 * The function listens for changes in the user's Firebase authentication state. If the user is authenticated, 
 * a GET request is made to the `/userRefresh` endpoint to retrieve the latest user data.
 * 
 * Depending on the returned data, actions are dispatched to either indicate that the user data has been loaded 
 * or to update the user data in the store.
 *
 * If any error occurs during this process, it will be logged and can be extended to handle or notify the error state.
 *
 * @function
 * @async
 *
 * @returns {Function} A Redux thunk that dispatches actions based on the state of the fetched user data.
 *
 * @example
 * dispatch(getUserRefreshedData());
 */
export const getUserRefreshedData = () => async (dispatch) => {
  try {
    // Dispatch the loading state for the user data.
    dispatch({ type: LOADING_USER });

    // Listen for changes in the user's authentication state.
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        // No user is authenticated at the moment.
        console.log("No user authenticated.");
        return;
      }

      // Attempt to fetch the refreshed user data from the backend.
      const res = await axios.get('/userRefresh');

      if (res.data && res.data.message && res.data.message === 'No new notifications') {
        // No new notifications found.
        dispatch({ type: LOADED_USER });
      } else {
        // Dispatch the refreshed user data to the store.
        dispatch({
          type: SET_USER,
          payload: res.data
        });
      }
    });
  } catch (error) {
    console.error("Error while fetching refreshed user data:", error);
    handleError(error, dispatch, 'getUserRefreshedData');
  } finally {
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Asynchronously fetches refreshed user data and dispatches relevant actions based on the fetched data.
 * 
 * A GET request is made to the '/userRefresh' endpoint. Depending on the returned data, various actions might be dispatched.
 *
 * @returns {Function} A Redux thunk that dispatches actions based on the state of the fetched user data.
 */
export const getUserDataReturned = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const res = await axios.get('/userRefresh');
    if (res.data && res.data.message && res.data.message === 'No new notifications') {
      // No new notifications
    } else if (res.data) {
      dispatch({ type: SET_USER, payload: res.data });
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    handleError(error, dispatch, 'getUserDataReturned');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Fetches the next set of challenges requested by a user.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreRequestedChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.get(`/getNextChallengesRequested/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch requested challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching requested challenges:", error);
    handleError(error, dispatch, 'moreRequestedChallenges');
  }
};

/**
 * Fetches the next set of challenges sent by a user.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreSentChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.get(`/getNextChallengesCreated/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch sent challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching sent challenges:", error);
    handleError(error, dispatch, 'moreSentChallenges');
  }
};

/**
 * Fetches the next set of challenges that the user has completed.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreHistoricChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.get(`/getNextChallengesCompleted/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch historic challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching historic challenges:", error);
    handleError(error, dispatch, 'moreHistoricChallenges');
  }
};

/**
 * Fetches the next set of challenges the user has voted on.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreVotedChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.get(`/getNextChallengesVoted/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch voted challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching more voted challenges:", error);
    handleError(error, dispatch, 'moreVotedChallenges');
  }
};

/**
 * Asynchronously fetches user profile data from the server based on the provided handle and the authentication state.
 * 
 * If the user is authenticated (determined by the Firebase auth state in Redux store or the 'authed' parameter), 
 * the profile data is fetched from the `/userAuth` endpoint. 
 * If the user isn't authenticated, the profile data is fetched from the `/user` endpoint.
 *
 * @param {string} handle - The user's handle to fetch the profile data for.
 * @param {boolean} [authed=false] - Optionally, a flag indicating if the user is considered authenticated outside of Firebase auth state. Defaults to false.
 * @returns {Promise<object>} A promise that resolves with the user's profile data or an error object.
 */
export const getUserProfileData = (handle, authed = false) => async (dispatch, getState) => {
  try {
    // Accessing the Redux store to get the firebaseUser
    const firebaseUser = getState().user.firebaseUser;
    // Determine if the user is authenticated based on Firebase user or the 'authed' flag
    const isAuthenticated = firebaseUser || authed;

    let res;
    if (isAuthenticated) {
      // Fetch data from `/userAuth` endpoint if the user is authenticated
      res = await axios.get(`/userAuth/${handle}`);
    } else {
      // Fetch data from `/user` endpoint if the user is not authenticated
      res = await axios.get(`/user/${handle}`);
    }

    return res.data || {};

  } catch (error) {
    console.error("Error fetching user profile data:", error);
    handleError(error, dispatch, 'getUserProfileData');
  }
};

/**
 * Asynchronously fetches user queue data from the server based on the provided handle.
 * 
 * If the user is authenticated (determined by the Firebase auth state in Redux store), 
 * the queue data is fetched from the `/userAuthQueue` endpoint. 
 * If the user isn't authenticated, the queue data is fetched from the `/userQueue` endpoint.
 *
 * @param {string} handle - The user's handle to fetch the queue data for.
 * @returns {Promise<object>} A promise that resolves with the user's queue data or an error object.
 */
export const getUserQueueData = (handle) => async (dispatch, getState) => {
  try {
    // Accessing the Redux store to get the firebaseUser
    const firebaseUser = getState().user.firebaseUser;

    let res;
    // If the user is authenticated, fetch data from `/userAuthQueue` endpoint
    if (firebaseUser) {
      res = await axios.get(`/userAuthQueue/${handle}`);
    } else {
      // If the user isn't authenticated, fetch data from the `/userQueue` endpoint
      res = await axios.get(`/userQueue/${handle}`);
    }

    return res.data || {};

  } catch (error) {
    console.error("Error fetching user queue data:", error);
    handleError(error, dispatch, 'getUserQueueData');
  }
};

/**
 * Fetches Meed point payouts.
 *
 * @async
 * @function
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched payout data.
 * @throws Will throw an error if the network request fails.
 */
export const getMeedPointPayouts = () => async (dispatch) => {
  try {
    const response = await axios.get('/getMeedPointPayouts');

    if (response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch Meed point payouts.');
    }

  } catch (error) {
    console.error("Error occurred while fetching Meed point payouts:", error);
    handleError(error, dispatch, 'getMeedPointPayouts');
  }
};

/**
 * Fetches Meed point purchases.
 *
 * @async
 * @function
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched purchase data.
 * @throws Will throw an error if the network request fails.
 */
export const getMeedPointPurchases = () => async (dispatch) => {
  try {
    const response = await axios.get('/getMeedPointPurchases');

    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch Meed point purchases.');
    }
  } catch (error) {
    console.error("Error occurred while fetching Meed point purchases:", error);
    handleError(error, dispatch, 'getMeedPointPurchases');
  }
};

/**
 * Uploads a user image.
 *
 * @param {Object} formData - The data for the image to be uploaded.
 * @returns {Function} A redux thunk action.
 */
/*
export const uploadImage = (formData) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    await axios.post('/user/image', formData);
    dispatch(getUserData());
  } catch (error) {
    console.error("Error uploading image:", error);
    handleError(error, dispatch, 'uploadImage');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};
*/

/**
 * Edits the details of a user.
 *
 * @param {Object} userDetails - The new details for the user.
 * @returns {Function} A redux thunk action that returns the server's response on success.
 */
export const editUserDetails = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/user', userDetails);

    if (response && response.data) {
      dispatch(getUserData());
      return response.data;
    } else if (response) {
      return response;
    }
  } catch (error) {
    handleError(error, dispatch, 'editUserDetails');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};


/**
 * Sends user details to the server via the `/contact` endpoint.
 * 
 * If the user is authenticated (determined by the Firebase auth state in Redux store), 
 * the user's Firebase UID is appended to the userDetails object.
 *
 * @param {object} userDetails - Details of the user making the contact request.
 * @returns {Promise<object|null>} A promise that resolves with the server's response data or null if an error occurs.
 */
export const contactUs = (userDetails) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_USER });

    const res = await axios.post('/contact', userDetails);

    if (res) {
      return res;
    }
  } catch (error) {
    handleError(error, dispatch, 'contactUs');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Reports a user by their ID.
 *
 * @param {string} userId - The ID of the user to report.
 * @returns {Function} A redux thunk action that returns the server's response on successful reporting.
 */
export const reportUser = (userId) => async (dispatch) => {
  try {
    const response = await axios.post(`/reportUser`, { userId });  // Assuming userId should be in an object format in the POST request

    if (response && response.data) {
      dispatch({
        type: REPORT_CHALLENGE,
        payload: response.data
      });
      return response.data;
    }
  } catch (error) {
    console.error("Error reporting user:", error);
    handleError(error, dispatch, 'reportUser');
  }
};

/**
 * Follows a user based on their details.
 *
 * @param {Object} userDetails - The details of the user to follow.
 * @returns {Function} A redux thunk action that returns the server's response upon successful following.
 */
export const follow = (userDetails) => async (dispatch) => {
  // TODO: This probably doesn't need to be like this. Optimistically update FE, etc.
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/follow', userDetails);
    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error following user:", error);
    handleError(error, dispatch, 'follow');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Unfollows a user based on their details.
 *
 * @param {Object} userDetails - The details of the user to unfollow.
 * @returns {Function} A redux thunk action that returns the server's response upon successful unfollowing.
 */
export const unfollow = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/unfollow', userDetails);
    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error unfollowing user:", error);
    handleError(error, dispatch, 'unfollow');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Ban a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to ban.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const ban = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/ban', userDetails);
    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error occurred while banning user:", error);
    handleError(error, dispatch, 'ban');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Unban a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to unban.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const unban = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/unban', userDetails);
    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error occurred while unbanning user:", error);
    handleError(error, dispatch, 'unban');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Assigns moderator privileges to a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to be granted mod privileges.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the full response.
 */
export const mod = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/mod', userDetails);
    if (response) {
      dispatch(getUserRefreshedData());
      return response; // returning the full response as was done in the original function.
    }
  } catch (error) {
    console.error('Error occurred while granting moderator privileges:', error);
    handleError(error, dispatch, 'mod');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Updates moderator privileges for an existing moderator using a POST request.
 * 
 * @param {Object} modDetails - Details of the moderator whose privileges are to be updated.
 * @returns {Promise<Object>} - A promise that resolves with the full response.
 */
export const updateModLevel = (modDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/updateModLevel', modDetails);
    if (response) {
      dispatch(getUserRefreshedData());
      return response; // returning the full response as was done in the original function.
    }
  } catch (error) {
    console.error('Error occurred while updating moderator privileges:', error);
    handleError(error, dispatch, 'updateModLevel');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Revokes moderator privileges from a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to revoke mod privileges from.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const unmod = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post('/unmod', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while revoking moderator privileges:', error);
    handleError(error, dispatch, 'unmod');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Makes a purchase of Meed points for a user.
 * 
 * @param {Object} userDetails - Details of the user making the purchase.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const purchaseMeedPoints = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post('/user/buyMeedPoints', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while purchasing Meed points:', error);
    handleError(error, dispatch, 'purchaseMeedPoints');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Applies meed points to the user and dispatches actions to fetch refreshed user data.
 * @param {Object} userDetails - The details of the user applying the meed points.
 * @returns {Promise} - A promise that resolves with the server's response or rejects with an error.
 */
export const fulfillMeedPoints = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post('/user/applyMeedPointPurchase', userDetails);

    if (response.data) {
      dispatch(getUserRefreshedData());
    }

    return response.data;
  } catch (error) {
    console.error('Error occurred while fulfilling Meed points:', error);
    handleError(error, dispatch, 'fulfillMeedPoints');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Marks notifications as read on the server.
 *
 * @param {Array} notificationIds - Array of notification IDs to be marked as read.
 * @returns {Function} Redux Thunk function.
 */
export const markNotificationsRead = (notificationIds) => async (dispatch) => {
  try {
    await axios.post('/notifications', notificationIds);
    dispatch({ type: MARK_NOTIFICATIONS_READ });
  } catch (error) {
    console.error("Error marking notifications as read:", error);
    handleError(error, dispatch, 'markNotificationsRead');
  }
};

/**
 * Initiates a transfer to a connected account and fetches refreshed user data upon successful transfer.
 * 
 * This function dispatches the `LOADING_USER` action before initiating the transfer.
 * Once the transfer is complete, it fetches refreshed user data by dispatching the `getUserRefreshedData` action.
 *
 * @returns {Promise<object|null>} A promise that resolves with the server's response data upon successful transfer or null if an error occurs.
 * @throws {Error} Throws an error if there's an issue with the axios request.
 */
export const transferToConnectedAccount = () => async (dispatch) => {
  // try {
  //   dispatch({ type: LOADING_USER });
  //   return await axios
  //     .get('/transferToConnectedAccount')
  //     .then((res) => {
  //       if (res && res.data) {
  //         dispatch(getUserRefreshedData());
  //         return res.data;
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // } catch(error) {

  // }
};

/**
 * Initiates the Stripe connection process.
 * 
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const stripeConnect = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.get('/stripeConnect');

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while initiating Stripe connection:', error);
    handleError(error, dispatch, 'stripeConnect');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Initiates the Stripe connection process for the dashboard.
 * 
 * @param {string} id - The id for the Stripe connection.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const stripeConnectDash = (id) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post('/stripeConnectDash', id);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while initiating Stripe dashboard connection:', error);
    handleError(error, dispatch, 'stripeConnectDash');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Authenticates the application with Twitch.
 * 
 * @param {Object} auth - The authentication parameters.
 * @param {string} auth.clientId - The client ID for Twitch authentication.
 * @param {string} auth.redirectUri - The redirect URI for Twitch authentication.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the authentication status and message.
 */
export const AuthenticateWithTwitch = (auth) => async (dispatch) => {
  const authConfig = {
    platform: 'twitch',
    baseUri: 'https://id.twitch.tv/oauth2/authorize',
    clientId: auth.clientId,
    redirectUri: auth.redirectUri,
    scope: 'user_read'
  };

  return AuthService.authenticate(authConfig, dispatch, (code) => getAuthToken('twitch', code)(dispatch));
};

/**
 * Authenticates the application with Streamlabs.
 * @warning This has some weird structure for how it returns data. Recommend investigating how it returns
 * info and how the rest of the application handles that info.
 * 
 * @param {Object} auth - The authentication parameters.
 * @param {string} auth.clientId - The client ID for Streamlabs authentication.
 * @param {string} auth.redirectUri - The redirect URI for Streamlabs authentication.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<string>} - A promise that resolves with the authentication status ("linked" or "not linked").
 */
export const AuthenticateWithStreamlabs = (auth) => async (dispatch) => {
  const authConfig = {
    platform: 'streamlabs',
    baseUri: 'https://streamlabs.com/api/v2.0/authorize',
    clientId: auth.clientId,
    redirectUri: auth.redirectUri,
    scope: 'alerts.create+alerts.write'
  };

  return AuthService.authenticate(authConfig, dispatch, (code) => getAuthToken('streamlabs', code)(dispatch));
};

/**
 * Authenticates the application with Discord.
 * @warning This has some weird structure for how it returns data. Recommend investigating how it returns
 * info and how the rest of the application handles that info.
 * 
 * @param {Object} auth - The authentication parameters.
 * @param {string} auth.clientId - The client ID for Discord authentication.
 * @param {string} auth.redirectUri - The redirect URI for Discord authentication.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<string>} - A promise that resolves with the authentication status ("linked" or "not linked").
 */
export const AuthenticateWithDiscord = (auth) => async (dispatch) => {
  const authConfig = {
    platform: 'discord',
    baseUri: 'https://discord.com/api/oauth2/authorize',
    clientId: auth.clientId,
    redirectUri: auth.redirectUri,
    scope: 'identify email'
  };

  return AuthService.authenticate(authConfig, dispatch, (code) => getAuthToken('discord', code)(dispatch));
};

/**
 * Authenticates the application with TikTok.
 * 
 * @param {Object} auth - The authentication parameters.
 * @param {string} auth.clientId - The client ID for TikTok authentication.
 * @param {string} auth.redirectUri - The redirect URI for TikTok authentication.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the authentication status and message.
 */
export const AuthenticateWithTikTok = (auth) => async (dispatch) => {
  const authConfig = {
    platform: 'tiktok',
    baseUri: 'https://www.tiktok.com/v2/auth/authorize/',
    clientId: auth.clientId,
    redirectUri: auth.redirectUri,
    scope: 'video.publish,user.info.basic,video.list',  // Add scopes for user info and video list
  };

  return AuthService.authenticate(authConfig, dispatch, (code) => getAuthToken('tiktok', code)(dispatch));
};

/**
 * Unlinks a platform from the user's account.
 *
 * @param {string} platform - The name of the platform to unlink (e.g., 'discord', 'streamlabs').
 * @returns {Function} Redux Thunk function.
 */
export const unlinkPlatform = (platform) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const res = await axios.post(`/unlink/${platform}`);

    if (res && res.data) {
      dispatch(getUserRefreshedData());
      return res.data;
    }
  } catch (error) {
    console.error(`Error unlinking ${platform}:`, error);
    handleError(error, dispatch, 'unlinkPlatform');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};


/**
 * Sends the received authorization code to the backend for user authentication.
 *
 * @param {string} platform - The name of the platform (e.g., 'twitch', 'streamlabs', 'discord').
 * @param {string} code - The authorization code from the platform.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the authentication status and message.
 * @throws Will throw an error if the request fails.
 */
const getAuthToken = (platform, code) => async (dispatch) => {
  try {
    const response = await axios.post(`/auth/${platform}`, { code });

    if (response && response.data) {
      if (response.data.error) {
        // Handle the error case with toast
        toast.error(response.data.error);
        dispatch({
          type: SET_ERRORS,
          payload: response.data,
        });
      } else {
        // Handle the success case with toast
        toast.success('Sign Up complete, redirecting...');
        // Optionally, you can dispatch an action here if needed
        // const actionType = `${platform.toUpperCase()}_LINK_SUCCESS`;
        // dispatch({ type: actionType, payload: response.data });
      }

      return response.data;
    } else {
      console.error('Unexpected response format:', response);
      throw new Error(`Unexpected response format from ${platform} auth.`);
    }
  } catch (error) {
    console.error(`Error occurred while authenticating with ${platform}:`, error);
    toast.error(`Error occurred while authenticating with ${platform}: ${error.message}`);
    handleError(error, dispatch, 'getAuthToken');
  }
};

/**
 * Adds a friend based on their user ID.
 *
 * @param {Object} friendDetails - The details of the friend to add.
 * @returns {Function} A redux thunk action that returns the server's response upon successful adding.
 */
export const addFriend = (friendDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post('/friend/add', friendDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error adding friend:", error);
    handleError(error, dispatch, 'addFriend');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Removes a friend based on their user ID.
 *
 * @param {Object} friendDetails - The details of the friend to remove.
 * @returns {Function} A redux thunk action that returns the server's response upon successful removal.
 */
export const removeFriend = (friendDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post('/friend/remove', friendDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error removing friend:", error);
    handleError(error, dispatch, 'removeFriend');
  } finally {
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Add a user's email to the waitlist.
 *
 * @param {string} email - The email to add to the waitlist.
 * @returns {Function} - Async function that dispatches actions and returns a Promise.
 */
export const addToWaitlist = (email) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post('/waitlist', { email });
    if (res.data) {
      dispatch({
        type: SHOW_TOAST,
        payload: 'Thank you for joining our waitlist! We\'ll notify you when we launch.',
      });
      dispatch({ type: ADD_TO_WAITLIST, payload: { email } });
      return res.data;
    }
  } catch (error) {
    console.error('Error while adding to waitlist:', error);
    handleError(error, dispatch, 'addToWaitlist');
  } finally {
    dispatch({ type: STOP_LOADING_UI });
  }
};