// meed/src/pages/styles/WaitlistStyles.js
import berryBg from '../../images/berryBg-removebg.png';

const WaitlistStyles = (theme) => ({
  ...theme.custom,
  waitlistContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    textAlign: 'center',
    padding: theme.spacing[3],
    backgroundImage: `url(${berryBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    backgroundColor: '#fff',
    padding: theme.spacing.unit * 4,
    borderRadius: theme.spacing.unit * 2,
    maxWidth: '75%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  pageTitle: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing[4],
  },
  explanationText: {
    fontSize: '1.5rem',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing[4],
  },
  textField: {
    margin: '10px auto 10px auto'
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    position: 'relative',
    borderRadius: '20px',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    textTransform: 'none',
    backgroundColor: '#5E2A84',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4B0082',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 2,
  },
  discordButton: {
    backgroundColor: '#7289DA', // Discord color
    '&:hover': {
      backgroundColor: '#5E76BA',
    },
  },
});

export default WaitlistStyles;