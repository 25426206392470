// meed/src/App.js
import React, { Component } from 'react';
import './util/admin';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import createMuiTheme from '@mui/material/styles/createTheme';
// Redux
import { connect } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED, SET_FIREBASE_USER, SET_USER, STOP_LOADING_UI } from './redux/types';
import { getUserData, logoutUser } from './redux/actions/userActions';
// Components
import Navbar from './components/layout/Navbar';
import themeObject from './util/theme';
import AuthRoute from './util/AuthRoute';
import ProtectedRoute from './util/ProtectedRoute';
// Pages
import team from './pages/team';
import integrationsTwitch from './pages/integrationsTwitch';
import integrationsDiscord from './pages/integrationsDiscord';
import integrations from './pages/integrations';
import about from './pages/about';
import login from './pages/login';
import forgotPassword from './pages/forgotPassword';
import buyMeedPoints from './pages/buyMeedPoints';
import search from './pages/search';
import NotFound from './pages/NotFound';
import service from './pages/service';
import contact from './pages/contact';
import privacy from './pages/privacy';
import Settings from './pages/settings';
import signup from './pages/signup';
import user from './pages/user';
import userChallengeQueue from './pages/userChallengeQueue';
import donate from './pages/donate';
import faqs from './pages/faqs';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastManager from './components/layout/Toast';
import {
  applyActionCode,
  getAuth,
} from "firebase/auth";
import LoadingScreen from './components/layout/LoadingScreen';
import handleError from './util/errorHandler';
import Waitlist from './pages/waitlist';
const auth = getAuth();

const theme = createMuiTheme(adaptV4Theme(themeObject));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSessionChecked: false,
      isFirebaseAuthChecked: false,
    };
  }
  /**
   * Component lifecycle method called immediately after the component is mounted.
   * This method orchestrates several key initialization tasks:
   * 1. Checks for an 'oobCode' in the URL to handle email verification via Firebase Auth. This step is crucial for users coming from an email verification link.
   * 2. Sets up a session check to determine if the user has an existing authenticated session on the server. Differentiates between no session cookie, an invalid session cookie, and a valid session to update the Redux store with the user's authentication status accordingly.
   * 3. Establishes a Firebase Auth state listener to synchronize the application's authentication state with Firebase's state.
   * 4. Subscribes to the Redux store to monitor the loading state of user data, ensuring the UI reflects the current state accurately.
   *
   * For authenticated users, actions are dispatched to fetch user data. For unauthenticated users with an invalid session cookie, a logout sequence is initiated. The presence of a session cookie, whether valid or not, is checked, and the frontend handles the absence of a session cookie gracefully without logging out the user, enhancing the user experience by reducing unnecessary logouts.
   *
   * Errors encountered during the session check, email verification, or other initialization tasks are handled gracefully, with appropriate feedback provided to the user or logged for debugging purposes.
   */
  componentDidMount() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const oobCode = urlParams.get('oobCode');

      const initializeApp = () => {
        // Subscribe to the Redux store
        this.unsubscribeFromStore = store.subscribe(() => {
          const { user } = store.getState();
          if (!user.loading) {
            this.setState({ isSessionChecked: true });
            if (this.unsubscribeFromStore) {
              this.unsubscribeFromStore(); // Unsubscribe only after loading is complete
            }
          }
        });
      
        axios.get('/check-session')
        .then(response => {
          if (response && response.data && response.data.authenticated) {
            // User is authenticated on the server side
            // const csrfToken = response.data.csrfToken;
            // axios.defaults.headers.common['x-csrf-token'] = csrfToken;
            store.dispatch({ type: SET_AUTHENTICATED });
            store.dispatch(getUserData());
          } else if (response && response.data.error && response.data.error === 'Invalid session cookie') {
            // Session cookie is invalid, initiate logout sequence
            store.dispatch(logoutUser(this.props.history, true));
            if (this.unsubscribeFromStore) {
              this.unsubscribeFromStore();
            }
            this.setState({ isSessionChecked: true });
          } else {
            // No session cookie present or other unauthenticated states
            // Consider handling this case differently, possibly no action needed
            this.setState({ isSessionChecked: true });
          }
        })
        .catch(error => {
          // Handle any errors that occur during the session check
          console.error("Error checking session:", error);
          this.setState({ isSessionChecked: true });
          handleError(error, store.dispatch, 'checkSession', false);
        });      
      
        // Set up Firebase Auth state listener to synchronize with server session
        auth.onAuthStateChanged(user => {
          this.setState({ isFirebaseAuthChecked: true }); // Indicate that Firebase has checked the auth state
        
          if (!user) {
            // No user is signed in.
            // Instead of logging out, ensure the application is in a "logged-out" state.
            store.dispatch({
              type: SET_USER,
              payload: {
                authenticated: false,
                loading: false,
                likes: [],
                notifications: [],
                credentials: {
                  handle: '',
                  createdAt: '',
                  imageUrl: '',
                  bio: '',
                  website: '',
                  location: '',
                  meedPointsBalance: '',
                  tokens: '',
                  twitch: ''
                }
              }
            });
          } else {
            // User is signed in, proceed with setting the user in your application's state.
            store.dispatch({ type: SET_FIREBASE_USER, payload: user });
            // Guarantess the loading bars stop (TODO: Figure out if this is necessary or if it's better elsehwere)
            store.dispatch({ type: STOP_LOADING_UI });
          }
        });
        
      };      

      if (oobCode) {
        applyActionCode(auth, oobCode)
          .then(() => {
            console.log('Email verified successfully!')
            // TODO: improve toast
            toast.success('Email verified successfully! Please set a password if you have not already.');
            // Update state or dispatch actions as needed
            this.setState({ emailVerified: true });
            // Call auth and sign in stuff here, so that the check-session flow that comes next is fine?
            // Proceed with app initialization after email verification
            initializeApp();
          })
          .catch((error) => {
            toast.error('Failed to verify email. Invalid or expired link.');
            console.error("Email verification error:", error);
            // Handle error, possibly redirect or update state
            // Consider proceeding with app initialization even if verification fails
            initializeApp();
          });
      } else {
        // If no oobCode is present, proceed with regular app initialization
        initializeApp();
      }
    } catch (error) {
      console.log("We are here");
      handleError(error, store.dispatch, 'componentDidMount', false);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeFromStore) {
      this.unsubscribeFromStore();
    }
  }

  render() {
    const { isSessionChecked } = this.state;

    if (!isSessionChecked) {
      return <LoadingScreen />;;
    }
    return (
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <Router>
            <Navbar />
            <div style={{ background: '#F3F3F3' }} className="">
              <ToastManager />
              <ToastContainer />
              <Switch>
                  {/* Allowed routes */}
                  <Route exact path="/" component={about} />
                  <Route exact path="/home" component={about} />
                  <Route exact path="/integrations/discord" component={integrationsDiscord} />
                  <Route exact path="/waitlist" component={Waitlist} />

                  {/* Commented out routes */}
                  {/*
                  <Route exact path="/about" component={about} />
                  <Route exact path="/team" component={team} />
                  <Route exact path="/integrations/twitch" component={integrationsTwitch} />
                  <Route exact path="/integrations" component={integrations} />
                  <AuthRoute exact path="/login" component={login} />
                  <AuthRoute exact path="/forgotPassword" component={forgotPassword} />
                  <AuthRoute exact path="/signup" component={signup} />
                  <AuthRoute exact path="/signup/:code" component={signup} />
                  <Route exact path="/faq" component={faqs} />
                  <Route exact path="/contact" component={contact} />
                  <Route exact path="/privacy" component={privacy} />
                  <Route exact path="/service" component={service} />
                  <Route exact path="/search" component={search} />
                  <Route exact path="/search/:default" component={search} />
                  <ProtectedRoute exact path="/settings/:uriType" component={Settings} />
                  <ProtectedRoute exact path="/buyMeedPoints" component={buyMeedPoints} />
                  <ProtectedRoute exact path="/settings" component={Settings} />
                  <Route exact path="/users/:handle" component={user} />
                  <Route exact path="/users/:handle/challengeQueue" component={userChallengeQueue} />
                  <Route exact path="/users/:handle/donate" component={donate} />
                  <Route
                    exact
                    path="/users/:handle/challenge/:challengeId"
                    component={user}
                  />
                  <ProtectedRoute exact path="/users/:handle/:paymentSuccess" component={user} />
                  <ProtectedRoute exact path="/users/:handle/:paymentFailure" component={user} />
                  <Route path="/not-found" component={NotFound} />
                  */}

                  {/* Catch-all route */}
                  <Route path="*">
                    <Redirect to="/waitlist" />
                  </Route>
                </Switch>
            </div>
          </Router>
        </MuiThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  initializing: state.user.initializing,
  authenticated: state.user.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
