import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// MUI Stuff
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Redux stuff
import { connect } from 'react-redux';
import { addToWaitlist } from '../redux/actions/userActions';
import WaitlistStyles from './styles/WaitlistStyles';
import { Link } from 'react-router-dom';

const MAX_EMAIL_LENGTH = 254; // RFC 5321 SMTP maximum

/**
 * Waitlist component for collecting user email addresses.
 * @extends {Component}
 */
class Waitlist extends Component {
  /**
   * Create a Waitlist component.
   * @param {Object} props - The component props.
   */
  constructor(props) {
    super(props);
    /**
     * @type {Object}
     * @property {string} email - The user's email address.
     * @property {Object} errors - Any errors that occur during form submission.
     * @property {boolean} submitted - Whether the form has been submitted.
     * @property {boolean} isValidEmail - Whether the current email is valid.
     */
    this.state = {
      email: '',
      errors: {},
      submitted: false,
      isValidEmail: false
    };
  }

  /**
   * Lifecycle method to update component state when new props are received.
   * @param {Object} nextProps - The next props the component will receive.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  /**
   * Validate an email address.
   * @param {string} email - The email address to validate.
   * @returns {boolean} True if the email is valid, false otherwise.
   */
  validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(email) && email.length <= MAX_EMAIL_LENGTH;
  }

  /**
   * Handle form submission.
   * @param {Event} event - The form submission event.
   * @returns {Promise<void>}
   */
  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.state.isValidEmail) {
      this.setState({ errors: { email: 'Please enter a valid email address' } });
      return;
    }

    const userData = {
      email: this.state.email.trim()
    };

    this.setState({ submitted: true });

    try {
      await this.props.addToWaitlist(userData);
      this.setState({ email: '', submitted: false, isValidEmail: false });
      // You might want to show a success message here
    } catch (err) {
      this.setState({ submitted: false });
    }
  };

  /**
   * Handle changes to the email input field.
   * @param {Event} event - The input change event.
   */
  handleChange = (event) => {
    const email = event.target.value.slice(0, MAX_EMAIL_LENGTH);
    this.setState({
      email: email,
      isValidEmail: this.validateEmail(email),
      errors: {}
    });
  };

  /**
   * Render the Waitlist component.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const { classes } = this.props;
    const { errors, isValidEmail, email } = this.state;

    return (
      <div className={classes.waitlistContainer}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.textContainer}>
              <Typography variant="h2" className={classes.pageTitle}>
                Meed is Coming Soon!
              </Typography>
              <Typography variant="h5" className={classes.explanationText}>
                Be the first to know when we launch. Join our waitlist and get early access!
              </Typography>
              <form noValidate onSubmit={this.handleSubmit}>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  className={classes.textField}
                  helperText={errors.email || (email && !isValidEmail ? `Please enter a valid email address (max ${MAX_EMAIL_LENGTH} characters)` : '')}
                  error={errors.email || (email && !isValidEmail) ? true : false}
                  value={this.state.email}
                  onChange={this.handleChange}
                  inputProps={{
                    maxLength: MAX_EMAIL_LENGTH,
                    'aria-label': 'Email address input'
                  }}
                  fullWidth
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={this.state.submitted || !isValidEmail}
                >
                  Join Waitlist
                </Button>
              </form>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/integrations/discord"
                  className={`${classes.button} ${classes.discordButton}`}
                >
                  Try Meed on Discord
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

/**
 * PropTypes for the Waitlist component.
 * @type {Object}
 */
Waitlist.propTypes = {
  /** Styles object */
  classes: PropTypes.object.isRequired,
  /** Function to add user to waitlist */
  addToWaitlist: PropTypes.func.isRequired,
  /** User object */
  user: PropTypes.object.isRequired,
  /** UI state object */
  UI: PropTypes.object.isRequired
};

/**
 * Map Redux state to component props.
 * @param {Object} state - The Redux state.
 * @returns {Object} The props derived from Redux state.
 */
const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

/**
 * Map Redux actions to component props.
 * @type {Object}
 */
const mapActionsToProps = {
  addToWaitlist
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(WaitlistStyles)(Waitlist));